import useAuth from '@/contexts/AuthContext';
import {
    addToFavoritesQueryFn,
    getFavoritesQueryFn,
    removeFromFavoritesQueryFn,
} from '@/queries/favorites';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export const useFavorite = () => {
    const queryClient = useQueryClient();
    const { user } = useAuth();
    const [allFavoritesIDs, setAllFavoritesIDs] = useState<string[]>([]);

    const { data: favorites, isFetching } = useQuery({
        queryKey: ['getFavoritesQueryFn'],
        queryFn: getFavoritesQueryFn,
        enabled: !!user,
    });

    useEffect(() => {
        if (favorites) {
            const ids: string[] = [];
            favorites.forEach((item) => {
                ids.push(item.id);
            });
            setAllFavoritesIDs(ids);
        } else {
            setAllFavoritesIDs([]);
        }
    }, [favorites]);

    const addToFavsMutation = useMutation({
        mutationFn: addToFavoritesQueryFn,
        onSuccess: (response) => {
            queryClient.invalidateQueries({
                queryKey: ['getFavoritesQueryFn'],
            });
        },
        onError: async (error) => {},
    });

    const removeFromFavsMutation = useMutation({
        mutationFn: removeFromFavoritesQueryFn,
        onSuccess: (response) => {
            queryClient.invalidateQueries({
                queryKey: ['getFavoritesQueryFn'],
            });
        },
        onError: async (error) => {},
    });

    const addToFavs = (id: string) => {
        setAllFavoritesIDs([...allFavoritesIDs, id]);
        addToFavsMutation.mutate(id);
    };

    const removeFromFavs = (id: string) => {
        setAllFavoritesIDs(allFavoritesIDs.filter((itemId) =>
            itemId !== id
        ));
        removeFromFavsMutation.mutate(id);
    };

    const isFavorite = (itemId?: string) => {
        return allFavoritesIDs?.find((id: string) => id === itemId);
    };

    return {
        favorites,
        isFetching,
        isFavorite,
        add: addToFavs,
        remove: removeFromFavs,
    };
};
